import React, { ComponentType, LazyExoticComponent, Suspense, useMemo } from "react";
import { Redirect } from "react-router-dom";

import { Spinner } from "../../../components";
import { PageFilters } from "../layout/PageFilters";
import * as FAC from "./featureAccessControl";
import { AiReviewResponseAudit } from "../../admin/aiReviewResponseAudit";
import { RouteNode } from "../navigator/RouteNode";

const ClientDashboardAppPage = loadable(React.lazy(() => import("../../../uiMigration/ClientDashboardAppPage")));

const routes: RouteNode[] = (
  [
    {
      path: "/notification/:notificationId",
      component: loadable(React.lazy(() => import("../../../services/notifications/NotificationLink"))),
    },
    {
      path: "/snapshot",
      component: loadable(
        React.lazy(() => import("../../snapshot/SnapshotWrapper")),
        { showTitleSkeleton: true }
      ),
      isAccessible: FAC.snapshotAllowed,
      drillInPages: [
        {
          path: "/snapshot/businessAnalysis",
          component: loadableWithFilters(
            React.lazy(
              () => import("../../snapshot/components/aiBusinessAnalysisDrilldown/BusinessAnalysisDrilldownWrapper")
            )
          ),
          isAccessible: FAC.isActionableInsightsEnabled,
        },
      ],
    },
    {
      path: "/reviews/summary",
      component: loadableWithFilters(React.lazy(() => import("../../reviews/pages/ReviewsSummaryPage"))),
      isAccessible: FAC.reviewsAllowed,
    },
    {
      path: "/reviews/storeComparison",
      component: loadableWithFilters(React.lazy(() => import("../../reviews/pages/ReviewStoreComparisonPage"))),
      isAccessible: FAC.reviewsStoreComparisonAllowed,
    },
    {
      path: "/reviews",
      component: loadableWithFilters(React.lazy(() => import("../../reviews/pages/ReviewListPage"))),
      isAccessible: FAC.reviewsAllowed,
      exact: true,
      drillInPages: [
        {
          path: "/reviews/details",
          title: "Reviews",
          component: loadable(React.lazy(() => import("../../reviews/pages/SingleReviewPage"))),
        },
      ],
    },
    //////////////////
    // start pulse //

    // COFE-2271: Pulse has two possible paths, and only one is ever enabled at a time:
    // - /sentiment for users with the Ask the AI Unleash flag
    // - /pulse for users without it

    // /pulse (old path)
    {
      path: "/pulse/summary",
      component: loadableWithFilters(React.lazy(() => import("../../pulse/SentimentOverviewPage"))),
      isAccessible: () => FAC.sentimentAllowed() && !FAC.isSignalsEnabled(),
    },
    {
      path: "/pulse/explorer",
      component: loadableWithFilters(React.lazy(() => import("../../pulse/SentimentExplorerPage"))),
      isAccessible: () => FAC.sentimentAllowed() && !FAC.isSignalsEnabled(),
    },
    {
      path: "/pulse/yourTopics",
      component: loadable(React.lazy(() => import("../../pulse/SentimentCustomTopicsPage"))),
      isAccessible: () => FAC.sentimentAllowed() && !FAC.isSignalsEnabled(),
      drillInPages: [
        {
          path: "/pulse/editCategory",
          component: loadable(React.lazy(() => import("../../pulse/SentimentEditCategoryPage"))),
        },
      ],
    },
    {
      path: "/pulse/storeComparison",
      component: loadableWithFilters(React.lazy(() => import("../../pulse/SentimentStoreComparisonPage"))),
      isAccessible: () => FAC.sentimentAllowed() && !FAC.isSignalsEnabled(),
    },

    // /sentiment (new path)
    {
      path: "/sentiment/summary",
      component: loadableWithFilters(React.lazy(() => import("../../pulse/SentimentOverviewPage"))),
      isAccessible: () => FAC.sentimentAllowed() && FAC.isSignalsEnabled(),
    },
    {
      path: "/sentiment/explorer",
      component: loadableWithFilters(React.lazy(() => import("../../pulse/SentimentExplorerPage"))),
      isAccessible: () => FAC.sentimentAllowed() && FAC.isSignalsEnabled(),
    },
    {
      path: "/sentiment/yourTopics",
      component: loadable(React.lazy(() => import("../../pulse/SentimentCustomTopicsPage"))),
      isAccessible: () => FAC.sentimentAllowed() && FAC.isSignalsEnabled(),
      drillInPages: [
        {
          path: "/sentiment/editCategory",
          component: loadable(React.lazy(() => import("../../pulse/SentimentEditCategoryPage"))),
          isAccessible: () => FAC.sentimentAllowed() && FAC.isSignalsEnabled(),
        },
      ],
    },
    {
      path: "/sentiment/storeComparison",
      component: loadableWithFilters(React.lazy(() => import("../../pulse/SentimentStoreComparisonPage"))),
      isAccessible: () => FAC.sentimentAllowed() && FAC.isSignalsEnabled(),
    },
    // end pulse //
    ///////////////
    //////////////////
    // start social //
    {
      path: "/social/summary/metrics",
      component: loadableWithFilters(React.lazy(() => import("../../social/pages/MetricsPage"))),
      isAccessible: FAC.socialAllowed,
      eventName: "Social Summary metrics",
    },
    {
      path: "/social/summary/topPerformingPosts",
      component: loadableWithFilters(React.lazy(() => import("../../social/pages/TopPerformingPostsPage"))),
      isAccessible: FAC.socialEngagementAllowed,
      eventName: "Social Summary topPerformingPosts",
    },
    {
      path: "/social/summary/providerBreakdown",
      component: loadableWithFilters(React.lazy(() => import("../../social/pages/ProviderBreakdownPage"))),
      isAccessible: FAC.socialEngagementAllowed,
      eventName: "Social Summary providerBreakdown",
    },
    {
      path: "/social/activity",
      component: loadableWithFilters(React.lazy(() => import("../../social/pages/SocialActivityPage"))),
      isAccessible: FAC.socialAllowed,
    },
    {
      path: "/social/hashtagMetrics",
      component: loadableWithFilters(React.lazy(() => import("../../social/pages/SocialHashtagKeywordPage"))),
      isAccessible: FAC.socialAllowed,
    },
    {
      path: "/social/publishing",
      component: loadableWithFilters(React.lazy(() => import("../../social/pages/SocialPublishing"))),
      isAccessible: FAC.socialPublishingAllowed,
      drillInPages: [
        {
          path: "/social/post",
          component: loadable(React.lazy(() => import("../../social/publishing/pages/SinglePostEditor"))),
          isAccessible: () => true,
        },
        {
          path: "/social/post/:postId/details",
          component: loadable(React.lazy(() => import("../../social/publishing/pages/SinglePostView"))),
        },
      ],
    },
    {
      path: "/social/reports",
      component: loadable(React.lazy(() => import("../../social/pages/SocialStoreComparisonReportPage"))),
      isAccessible: FAC.socialReportsAllowed,
    },
    // end social //
    ////////////////
    {
      path: "/media",
      component: loadableWithFilters(React.lazy(() => import("../../media/MediaContainer"))),
      isAccessible: FAC.mediaAllowed,
    },
    // Start listings //
    ////////////////
    {
      path: "/listings/accuracy",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/AccuracyReportPage"))),
      isAccessible: () => FAC.listingAccuracyReportAllowed() && !FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/accuracy/managed",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingAccuracyReportAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/accuracy/monitored",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingAccuracyReportAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/summary",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/SummaryReportPage"))),
      isAccessible: () => FAC.listingsAllowed() && !FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/summary/managed",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingsAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/summary/monitored",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingsAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/listingOptimization",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/OptimizationsReportPage"))),
      isAccessible: () => FAC.listingOptimizationAllowed() && !FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/listingOptimization/managed",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingOptimizationAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/listingOptimization/monitored",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingOptimizationAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/missingListings",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/MissingReportPage"))),
      isAccessible: () => FAC.listingMissingReportAllowed() && !FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/missingListings/managed",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingMissingReportAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/missingListings/monitored",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingMissingReportAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/duplicateListings",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/DuplicatesReportPage"))),
      isAccessible: () => FAC.listingDuplicatesReportAllowed() && !FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/duplicateListings/managed",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingDuplicatesReportAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/duplicateListings/monitored",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/index"))),
      isAccessible: () => FAC.listingDuplicatesReportAllowed() && FAC.hasListingsToggleAndLlmEnabled(),
    },
    {
      path: "/listings/publishingStatus",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/ListingsPublishingReportPage"))),
      eventName: "Listing Publish Status",
      isAccessible: () =>
        FAC.listingsAllowed() &&
        FAC.hasListingsToggleAndLlmEnabled() &&
        (FAC.listingPublishStatusReportAllowed() || FAC.isImpersonatedByGlobalAccessAdmin()),
      drillInPages: [
        {
          path: "/listings/publishingStatus/errors",
          component: loadable(React.lazy(() => import("../../listings/pages/PublishErrorsPage"))),
        },
      ],
    },
    {
      path: "/listings/searchAnalyticsReport",
      component: loadableWithFilters(React.lazy(() => import("../../listings/pages/SearchAnalyticsReportPage"))),
      isAccessible: FAC.listingSearchAnalyticsReportAllowed,
    },
    {
      path: "/listings/searchAnalyticsStoreComparison",
      component: loadableWithFilters(
        React.lazy(() => import("../../listings/pages/SearchAnalyticsStoreComparisonReportPage"))
      ),
      isAccessible: FAC.listingSearchAnalyticsReportAllowed,
    },
    {
      path: "/listings/localPagesReport",
      component: loadableWithFilters(React.lazy(() => import("../../admin/manageTemplates/LocalPagesReport"))),
      isAccessible: FAC.localPagesAllowed,
    },
    {
      path: "/listings/locations",
      component: loadableWithFilters(React.lazy(() => import("../../locations/pages"))),
      isAccessible: FAC.locationManagementAllowed,
      drillInPages: [
        {
          path: "/listings/locations/editor/add",
          component: loadable(React.lazy(() => import("../../locations/pages"))),
        },
        {
          path: "/listings/locations/editor/edit/:locationId",
          component: loadable(React.lazy(() => import("../../locations/pages"))),
        },
        {
          path: "/listings/locations/editor/edit",
          component: loadable(React.lazy(() => import("../../locations/pages"))),
        },
        {
          path: "/listings/locations/editor/schedule/:locationUpdateJobId",
          component: loadable(React.lazy(() => import("../../locations/pages"))),
        },
        {
          path: "/listings/locations/editor/schedule",
          component: loadable(React.lazy(() => import("../../locations/pages"))),
        },
        {
          path: "/locations/:locationId/competitors",
          component: ClientDashboardAppPage,
        },
        {
          path: "/locations/:locationId/competitors/create",
          component: ClientDashboardAppPage,
        },
        {
          path: "/locations/:locationId/competitors/:competitorId/edit",
          component: ClientDashboardAppPage,
        },
      ],
    },
    {
      path: "/listings/pendingUpdates",
      component: loadable(React.lazy(() => import("../../locations/pages"))),
      isAccessible: FAC.locationManagementAllowed,
    },
    {
      path: "/listings/searchToAdd",
      component: loadable(React.lazy(() => import("../../locations/pages"))),
      isAccessible: FAC.isImpersonatedByGlobalAccessAdmin,
    },

    // end listings //
    ////////////////
    {
      path: "/rankings/avgRankings",
      component: loadableWithFilters(React.lazy(() => import("../../rankings/AverageRankingContainer"))),
      isAccessible: FAC.rankingsAllowed,
    },
    {
      path: "/rankings/page1Rankings",
      component: loadableWithFilters(React.lazy(() => import("../../rankings/Page1RankingContainer"))),
      isAccessible: FAC.rankingsAllowed,
    },
    {
      path: "/reviewBuilder",
      component: loadable(React.lazy(() => import("../../reviewBuilder/ReviewBuilderPage"))),
      isAccessible: () => FAC.reviewBuilderAllowed() && !FAC.surveysAllowed(),
    },
    {
      path: "/workflow2/settings",
      component: loadable(React.lazy(() => import("../../workflow/pages/WorkflowSettings"))),
      eventName: "Task Settings",
      isAccessible: FAC.workflowAdminAllowed,
    },
    {
      path: "/workflow2/taskReport",
      component: loadable(React.lazy(() => import("../../workflow/pages/WorkflowTaskReport"))),
      eventName: "Task Report",
      isAccessible: FAC.workflowAllowed,
    },
    {
      path: "/workflow2/userReport",
      component: loadable(React.lazy(() => import("../../workflow/pages/WorkflowUserReport"))),
      eventName: "Task User Report",
      isAccessible: FAC.workflowAllowed,
    },
    {
      path: "/workflow2",
      component: loadableWithFilters(React.lazy(() => import("../../workflow/pages/WorkflowTasks"))),
      eventName: "Task Report",
      isAccessible: FAC.workflowAllowed,
    },
    {
      path: "/competitors",
      component: loadableWithFilters(React.lazy(() => import("../../competitors/CompetitorsReportPage"))),
      isAccessible: FAC.brandCompetitorUIEnabled,
    },
    {
      path: "/scores/summary",
      component: ClientDashboardAppPage,
      isAccessible: FAC.scoringAllowed,
    },
    {
      path: "/scores/storeComparison/locations",
      component: ClientDashboardAppPage,
      isAccessible: FAC.scoringStoreComparisonAllowed,
    },
    {
      path: "/scores/storeComparison/groups",
      component: ClientDashboardAppPage,
      isAccessible: FAC.scoringStoreComparisonAllowed,
    },
    {
      path: "/scores/manageScoringRedirect",
      component: () => <Redirect to="/admin/v2/manageScoring" />,
      isAccessible: FAC.scoreManagementAllowed,
    },
    {
      path: "/surveys/campaigns",
      component: loadable(React.lazy(() => import("../../surveys/pages/CampaignsPage"))),
      isAccessible: FAC.surveysAllowed,
      drillInPages: [
        {
          path: "/surveys/campaigns/:id/dashboard",
          component: loadable(React.lazy(() => import("../../reviewBuilder/ReviewBuilderCampaignDrillInContainer"))),
        },
        {
          path: "/surveys/campaigns/:id/responses",
          component: loadable(React.lazy(() => import("../../reviewBuilder/ReviewBuilderCampaignDrillInContainer"))),
        },
        {
          path: "/surveys/campaigns/:id/contacts",
          component: loadable(React.lazy(() => import("../../reviewBuilder/ReviewBuilderCampaignDrillInContainer"))),
          exact: true,
        },
        {
          path: "/surveys/campaigns/:id/report",
          component: loadable(React.lazy(() => import("../../reviewBuilder/ReviewBuilderCampaignDrillInContainer"))),
          isAccessible: FAC.surveysAllowed,
          exact: true,
        },
        {
          path: "/surveys/campaigns/typeSelector",
          component: loadable(React.lazy(() => import("../../reviewBuilder/CampaignTypeSelector"))),
          isAccessible: FAC.surveysAllowed,
          exact: true,
        },
      ],
    },
    {
      path: "/surveys/surveyForms",
      component: loadable(React.lazy(() => import("../../surveys/pages/SurveysPage"))),
      isAccessible: () => FAC.surveysAllowed() && !FAC.surveyReportingView(),
      drillInPages: [
        {
          path: "/surveys/surveyForms/templates",
          component: loadable(React.lazy(() => import("../../reviewBuilder/templates/ReviewBuilderTemplates"))),
        },
        {
          path: "/surveys/surveyForms/email/templates",
          component: loadable(React.lazy(() => import("../../surveys/pages/EmailsPage"))),
          isAccessible: () => FAC.surveysAllowed() && !FAC.surveyReportingView() && FAC.reviewGenEnabled(),
        },
      ],
    },
    {
      path: "/surveys/survey",
      component: loadable(React.lazy(() => import("../../reviewBuilder/survey/SurveyCreator"))),
      isAccessible: FAC.surveysAllowed,
    },
    {
      path: "/surveys/survey/:surveyId",
      component: loadable(React.lazy(() => import("../../reviewBuilder/survey/SurveyCreator"))),
      isAccessible: FAC.surveysAllowed,
    },
    {
      path: "/surveys/emailTemplates",
      component: loadable(React.lazy(() => import("../../surveys/pages/EmailsPage"))),
      isAccessible: () => FAC.surveysAllowed() && !FAC.surveyReportingView() && !FAC.reviewGenEnabled(),
    },
    {
      path: "/surveys/reviewGeneration",
      component: loadable(React.lazy(() => import("../../surveys/pages/reviewGeneration/ReviewGenerationPage"))),
      isAccessible: () => FAC.surveysAllowed() && FAC.reviewGenEnabled(),
      drillInPages: [
        {
          path: "/surveys/reviewGeneration/templates",
          component: loadable(React.lazy(() => import("../../surveys/pages/reviewGeneration/Templates"))),
        },
        {
          path: "/surveys/reviewGeneration/messagingEditor",
          component: loadable(
            React.lazy(() => import("../../surveys/pages/reviewGeneration/messagingEditor/MessagingEditor"))
          ),
        },
      ],
    },
    {
      path: "/surveys/widgets",
      component: loadable(React.lazy(() => import("../../surveys/pages/WidgetsPage"))),
      isAccessible: () => FAC.surveysAllowed() && !FAC.surveyReportingView(),
      drillInPages: [
        {
          path: "/surveys/widgets/templates",
          component: loadable(React.lazy(() => import("../../surveys/pages/WidgetEditor"))), // Placeholder till Widgets templates page is migrated to React
        },
        {
          path: "/surveys/widgets/create",
          component: loadable(React.lazy(() => import("../../surveys/pages/WidgetEditor"))), // Placeholder till Widgets editor page is migrated to React
        },
        {
          path: "/surveys/widgets/:widgetId/edit",
          component: loadable(React.lazy(() => import("../../surveys/pages/WidgetEditor"))),
        },
        {
          path: "/surveys/widgets/:widgetId/create/true",
          component: loadable(React.lazy(() => import("../../surveys/pages/WidgetEditor"))),
        },
      ],
    },
    {
      path: "/surveys/campaigns/:campaignId/contacts/upload",
      component: loadable(React.lazy(() => import("../../surveys/pages/MappingsUploadFilePage"))),
      isAccessible: () => FAC.surveysAllowed(),
    },
    {
      path: "/surveys/campaigns/:campaignId/contacts/sftp",
      component: loadable(React.lazy(() => import("../../surveys/pages/SftpAutomationPage"))),
      isAccessible: () => FAC.surveysAllowed(),
    },
    {
      path: "/surveys/campaigns/:campaignId/contacts/yardi",
      component: loadable(React.lazy(() => import("../../surveys/pages/EditYardiAutomationPage"))),
      isAccessible: () => FAC.surveysAllowed(),
    },
    {
      path: "/emailAlerts/manage",
      component: loadable(React.lazy(() => import("../../emails/EmailAlertsPage"))),
      isAccessible: FAC.emailAlertsAllowed,
      drillInPages: [
        {
          path: "/emailAlerts/manage/add",
          title: "Email Reports",
          component: loadable(React.lazy(() => import("../../emails/AddEmailAlertsPage"))),
        },
        {
          path: "/emailAlerts/manage/:alertId/edit",
          title: "Email Reports",
          component: loadable(React.lazy(() => import("../../emails/EditEmailAlertsPage"))),
        },
        {
          path: "/emailAlerts/manage/:alertId/copy",
          title: "Email Reports",
          component: loadable(React.lazy(() => import("../../emails/CopyEmailAlertsPage"))),
        },
      ],
    },
    //////////////////////
    // start analytics //
    {
      path: "/analytics/reports",
      component: loadable(React.lazy(() => import("../../insights"))),
      isAccessible: FAC.analyticsStudioAllowed,
      drillInPages: [
        {
          path: "/analytics/select-template",
          component: loadable(React.lazy(() => import("../../insights"))),
          drillInPages: [
            {
              path: "/analytics/create/:templateId",
              component: loadable(React.lazy(() => import("../../insights"))),
            },
          ],
        },
        {
          path: "/analytics/:reportId/:viewMode(edit|view)",
          component: loadable(React.lazy(() => import("../../insights"))),
        },
        {
          path: "/analytics/create-component",
          component: loadable(React.lazy(() => import("../../insights"))),
        },
      ],
    },
    {
      path: "/analytics/query-builder",
      component: loadable(React.lazy(() => import("../../insights"))),
      isAccessible: FAC.analyticsStudioAllowed,
      drillInPages: [
        {
          path: "/analytics/list-data-source",
          component: loadable(React.lazy(() => import("../../insights"))),
        },
        {
          path: "/analytics/create-data-source",
          component: loadable(React.lazy(() => import("../../insights"))),
        },
        {
          path: "/analytics/edit-data-source/:dataSourceId",
          component: loadable(React.lazy(() => import("../../insights"))),
        },
      ],
    },
    {
      path: "/analytics/bi-integration",
      component: loadable(React.lazy(() => import("../../insights"))),
      isAccessible: FAC.analyticsStudioAllowed,
    },
    // end analytics //
    //////////////////
    {
      path: "/admin/v2/user",
      component: loadable(React.lazy(() => import("../../admin/user/ProfileEditorPage"))),
    },
    {
      path: "/admin/v2/users",
      component: ClientDashboardAppPage,
      isAccessible: FAC.userManagementAllowed,
      drillInPages: [
        {
          path: "/admin/v2/users/create",
          title: "Users",
          component: loadable(React.lazy(() => import("../../admin/user/UserCreatePage"))),
        },
        {
          path: "/admin/v2/users/:userId/edit",
          title: "Users",
          component: loadable(React.lazy(() => import("../../admin/user/UserEditorPage"))),
        },
      ],
    },
    {
      path: "/admin/v2/groups",
      component: ClientDashboardAppPage,
      isAccessible: FAC.groupManagementAllowed,
    },
    {
      path: "/admin/v2/manageScoring",
      component: ClientDashboardAppPage,
      isAccessible: FAC.scoreManagementAllowed,
    },
    {
      path: "/admin/v2/accounts",
      component: ClientDashboardAppPage,
      isAccessible: FAC.accountManagementAllowed,
    },
    {
      path: "/admin/v2/brand",
      component: ClientDashboardAppPage,
      isAccessible: FAC.brandManagementAllowed,
    },
    {
      path: "/admin/v2/userRoles",
      component: ClientDashboardAppPage,
      isAccessible: FAC.roleManagementAllowed,
    },
    {
      path: "/chatmeterAdminRedirect",
      component: () => <Redirect to="/chatmeterAdmin" />,
      isAccessible: FAC.isGlobalAdmin,
    },
    {
      path: "/admin/v2/customProviders",
      component: ClientDashboardAppPage,
      isAccessible: FAC.userManagementAllowed,
    },
    {
      path: "/admin/v2/competitors",
      component: loadable(React.lazy(() => import("../../admin/brands/competitorManagement/CompetitorsPage"))),
      isAccessible: FAC.brandCompetitorUIEnabled,
    },
    {
      path: "/admin/v2/credentials/list",
      component: loadable(React.lazy(() => import("../../admin/credentialManagement/ThirdPartyCredentialListPage"))),
      isAccessible: FAC.credentialManagementAllowed,
      drillInPages: [
        {
          path: "/admin/v2/credentials/:credentialId",
          title: "Third-Party Account Details",
          component: loadable(
            React.lazy(() => import("../../admin/credentialManagement/ThirdPartyCredentialDetailsPage"))
          ),
          isAccessible: FAC.credentialManagementAllowed,
        },
      ],
    },
    {
      path: "/admin/v2/credentialPages",
      component: loadable(React.lazy(() => import("../../admin/credentialManagement/CredentialPageListPage"))),
      isAccessible: FAC.credentialManagementAllowed,
    },
    {
      path: "/admin/v2/upload",
      component: ClientDashboardAppPage,
      isAccessible: FAC.uploadToolAllowed,
    },
    {
      path: "/admin/v2/manageTemplates",
      component: loadable(React.lazy(() => import("../../admin/manageTemplates/ManageTemplatesList"))),
      isAccessible: FAC.isImpersonatedByGlobalAccessAdmin,
    },
    {
      path: "/admin/v2/localPagesUploader",
      component: loadable(React.lazy(() => import("../../admin/manageTemplates/LocalPagesUploader"))),
      isAccessible: FAC.isImpersonatedByGlobalAccessAdmin,
    },
    {
      path: "/admin/v2/changeLogs",
      component: loadable(React.lazy(() => import("../../admin/changeLogs/ChangeLogsBrowser"))),
      isAccessible: FAC.isGlobalAdmin,
    },
    {
      path: "/admin/v2/notifications/settings",
      component: loadable(React.lazy(() => import("../../notifications/settings/NotificationTriggersList"))),
      isAccessible: FAC.notificationSettingsAllowed,
    },
    {
      path: "/admin/v2/instantAuditsUsage",
      component: ClientDashboardAppPage,
      isAccessible: FAC.instantAuditsAllowed,
    },
    {
      path: "/admin/v2/aiReviewResponseAudit",
      component: AiReviewResponseAudit,
      isAccessible: FAC.isImpersonatedByGlobalAccessAdmin,
    },
    {
      path: "/admin/v2/locations/searchToAdd",
      component: ClientDashboardAppPage,
      isAccessible: FAC.isImpersonatedByGlobalAccessAdmin,
    },
    {
      path: "/sandbox",
      component: loadableWithFilters(React.lazy(() => import("../SandboxDemoPage"))),
    },
    {
      path: "/signals",
      component: loadableWithFilters(React.lazy(() => import("../../askTheAI/AskTheAIPage"))),
      isAccessible: () => FAC.isSignalsEnabled() && FAC.reviewsAllowed() && FAC.showSignals(),
    },
    {
      path: "/riskMonitoring",
      component: loadableWithFilters(React.lazy(() => import("../../riskDetection/RiskDetectionPage"))),
      isAccessible: () => FAC.isRiskMonitoringEnabled(),
    },
    {
      path: "/competitiveIntelligence",
      component: loadable(React.lazy(() => import("../../competitorIntel/CompetitorIntelPage"))),
      isAccessible: () => FAC.competitorIntelEnabled(),
    },
  ] as RouteNode[]
)
  .concat(
    // various prefixes for internal chatmeter/billing admin section still in ui-router
    [
      "/chatmeterAdmin",
      "/chatmeterAdmin/billingAccounts",
      "/listingManagement/listingManagementStats/recentLocations",
      "/chatmeterAdmin/keywords",
      "/chatmeterAdmin/verticals",
      "/healthmeter",
      "/healthmeter/listingStats/summary",
      "/yelpSubscription",
      "/adviceLocalBackSync",
      "/manageTool",
    ].map((path) => {
      return {
        path,
        component: ClientDashboardAppPage,
        isAccessible: FAC.isGlobalAdmin,
      };
    })
  )
  .concat(
    // internal chatmeter/billing admin sections with react components
    [
      {
        path: "/listingSummary",
        component: loadable(React.lazy(() => import("../../listingSummary"))),
        isAccessible: FAC.isGlobalAdmin,
      },
      {
        // billing admin
        path: "/admin/listingManagement",
        component: loadable(React.lazy(() => import("../../listingManagement"))),
        isAccessible: FAC.isGlobalAdmin,
      },
      {
        path: "/admin/invoicing/:billingAccountId",
        component: loadable(React.lazy(() => import("../../admin/billing/InvoicingConfigPage"))),
        isAccessible: FAC.isGlobalAdmin,
      },
      {
        path: "/chatmeterAdmin/manageToolUploader",
        component: loadable(
          React.lazy(() => import("../../chatmeterAdmin/manageToolUploader/pages/manageToolUploaderPage"))
        ),
        isAccessible: FAC.isGlobalAdmin,
      },
      {
        path: "/admin/billingAccount/:billingAccountId",
        component: loadable(
          React.lazy(() => import("../../admin/billing/BillingAccountEditor/BillingAccountEditorPage"))
        ),
        isAccessible: FAC.isGlobalAdmin,
      },
      {
        path: "/admin/config/:accountId/edit",
        component: loadable(React.lazy(() => import("../../chatmeterAdmin/brandConfig/BrandConfigTogglePage"))),
        isAccessible: FAC.isGlobalAdmin,
      },
    ] as RouteNode[]
  );
/**
 * component that displays some loading state in the general shape of a page.
 * Page may be configured via props
 **/
function PageLoaderSpinner({ showFilterSkeleton = false, showTitleSkeleton = false }) {
  const filterStyle = {
    marginTop: "7px",
  };
  const memoizedFilterStyle = useMemo(() => filterStyle, []);
  return (
    <React.Fragment>
      {showFilterSkeleton && (
        <PageFilters>
          <div className="skeleton-filter" style={memoizedFilterStyle} />
          <div className="skeleton-filter" style={memoizedFilterStyle} />
          <div className="skeleton-filter" style={memoizedFilterStyle} />
          <div className="skeleton-filter" style={memoizedFilterStyle} />
        </PageFilters>
      )}
      <div className="mt-5">
        <Spinner timeout={1000} className="m-auto" size="xl" center />
      </div>
    </React.Fragment>
  );
}

/**
 * constructs a react component bound to the passed in "Component" promise. For chunked async loading of pages
 * @param Component
 * @param showFiltersSkeleton - should this page have filters loading state
 * @param showTitleSkeleton - should this page have a title loading state
 * @param getComponentProps - additional props to be passed to the Component
 * @return {*} - a functional react component constructor
 */
function loadable<P extends object>(
  Component: LazyExoticComponent<ComponentType<P>>,
  { showFiltersSkeleton = false, showTitleSkeleton = false } = {}
) {
  const LoadableComponent: ComponentType<P> = (props) => {
    return (
      <Suspense
        fallback={<PageLoaderSpinner showFilterSkeleton={showFiltersSkeleton} showTitleSkeleton={showTitleSkeleton} />}
      >
        <Component {...props} />
      </Suspense>
    );
  };

  return LoadableComponent;
}
/**
 * convenience function with all the bells and whistle options of a page included,
 * so as to not need to manually specify all the loading skeletons
 * @param Component
 * @param getComponentProps A function for dynamic props to be passed to the component
 * @return {*}
 */
function loadableWithFilters<P extends object>(Component: LazyExoticComponent<ComponentType<P>>) {
  return loadable(Component, { showFiltersSkeleton: true, showTitleSkeleton: true });
}

export default routes;
